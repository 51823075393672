import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getSettings"]),
  },
  methods: {
    openWindow() {
      window.open(
        `http://panel.rezzervasyon.com/#/app/external-login?domain=${this.getSettings.website}`,
        "_blank",
        "height=800px, width=500px"
      );
    },
  },
};
