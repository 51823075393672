<template>
  <section class="vid-title-sec">
    <!-- is active geldiğinde içeri gönder -->
    <delete-product
      :product="form"
      v-if="form.buyerCount === 0"
    ></delete-product>
    <div class="container">
      <form @submit.prevent="SET_MIDDLEWARE">
        <Processes v-model="processList"></Processes>
        <Cropper :image="form.imagePath" @change:image="SET_FILE"></Cropper>
        <Video
          v-if="getSettings.departmentId !== 2"
          @change:link="SET_LINK"
          :video="form.videoPath"
          @change:video="SET_VIDEO"
        ></Video>
        <div class="vid-title">
          <h2 class="title-hd">{{ $t("enterSessionTitle") }} *</h2>
          <div class="form_field">
            <input
              type="text"
              required
              v-model="form.title"
              maxlength="100"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <!--vid-title-->
        <div class="abt-vidz-pr">
          <h2 class="title-hd">{{ $t("shortDesc") }}</h2>
          <div class="form_field">
            <textarea
              :placeholder="$t('shortDescMax250')"
              required
              v-model="form.description"
              maxlength="250"
            ></textarea>
          </div>
        </div>
        <!--abt-vidz-->
        <div class="abt-vidz-pr">
          <h2 class="title-hd">{{ $t("sessionOverview") }} *</h2>
          <div class="form_field">
            <vue-editor
              id="editor"
              :placeholder="$t('explainSessionDetail')"
              :editorToolbar="[
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [
                  { align: '' },
                  { align: 'center' },
                  { align: 'right' },
                  { align: 'justify' },
                ],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ color: [] }, { background: [] }],
                ['link'],
                ['clean'],
              ]"
              v-model="form.overview"
            >
            </vue-editor>
          </div>
        </div>
        <!--abt-vidz-->
        <div class="cls-vidz">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="option">
                <h2 class="title-hd">{{ $t("selectSessionLang") }}*</h2>
                <div class="form_field">
                  <select required v-model="form.langCode">
                    <option value="tr">{{ $t("tr") }}</option>
                    <option value="en">{{ $t("en") }}</option>
                    <option value="de">{{ $t("de") }}</option>
                  </select>
                </div>
              </div>
              <!--option end-->
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="option">
                <h2 class="title-hd">{{ $t("sessionLang") }} *</h2>
                <div class="form_field">
                  <select required v-model="form.level">
                    <option :value="1">{{ $custom$t("beginning") }}</option>
                    <option :value="2">{{ $custom$t("intermediate") }}</option>
                    <option :value="3">{{ $custom$t("advanced") }}</option>
                    <option :value="4">{{ $t("all") }}</option>
                  </select>
                </div>
              </div>
              <!--option end-->
            </div>
            <div class="col-sm-6 col-12">
              <div class="option">
                <h2 class="title-hd">{{ $t("category") }} *</h2>
                <div class="form_field">
                  <select
                    :disabled="getCategoriesAreLoading"
                    required
                    v-model="form.categoryId"
                  >
                    <option
                      :value="item.id"
                      v-for="(item, i) in getCategories"
                      :key="i"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!--option end-->
            </div>
          </div>
        </div>
        <div>
          <publish-on-home-page
            v-model="form.isShowcase"
          ></publish-on-home-page>
        </div>
        <div>
          <SharableProduct v-model="form.isSharable"></SharableProduct>
        </div>
        <div>
          <SelectProductOwner
            :edit="edit"
            v-model="form.userInfo"
            ref="userList"
          ></SelectProductOwner>
        </div>
        <!--cls-vidz end-->
        <div class="clearfix"></div>
        <div class="btn-sbmit">
          <button type="submit" :disabled="getNewSeanceIsLoading">
            <loading v-if="getNewSeanceIsLoading"></loading>
            <span v-else> {{ $t("submintSession") }}</span>
          </button>
        </div>
      </form>
    </div>
    <hr />
    <IsActiveProduct
      :isActive="1"
      :form="product"
      v-if="edit"
    ></IsActiveProduct>
  </section>
  <!--vid-title-sec-->
</template>

<script>
import Cropper from "./ImageCropper.vue";
import Video from "./Video.vue";
import { VueEditor } from "vue2-editor";
import IsActiveProduct from "./IsActiveProduct.vue";
import SelectProductOwner from "./SelectProductOwner.vue";
import Processes from "./Processes.vue";
import PublishOnHomePage from "./PublishOnHomePage.vue";
import SharableProduct from "./SharableProduct.vue";
import DeleteProduct from "./DeleteProduct.vue";
export default {
  components: {
    Cropper,
    Video,
    VueEditor,
    SelectProductOwner,
    IsActiveProduct,
    Processes,
    PublishOnHomePage,
    SharableProduct,
    DeleteProduct,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        user: null,
        title: "",
        description: "",
        overview: "",
        imagePath: "",
        videoPath: "",
        image: null,
        video: null,
        langCode: "tr",
        level: 1,
        hiddenInfo: "",
        isSharable: 0,
        isOffer: 0,
        isShowcase: 0,
        categoryId: null,
      },
      processList: [],
      files: {
        image: "",
        video: "",
      },
    };
  },
  computed: {
    // kategorilerin listesi alınır.
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    // kategorilerin listesi alınır.
    getCategories() {
      return this.$store.state.Category.categories;
    },
    // kateori listesi loading
    getCategoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
    // ürün ekleme loading
    getNewSeanceIsLoading() {
      return this.$store.state.Seances.newSeancesIsLoading;
    },
    // rez active room
    getRezervationRoom() {
      return this.$store.state.Rezervation.activeRoom;
    },
  },
  methods: {
    // eğer yeni kayıt query'de category var ise.
    SET_CATEGORY() {
      const categoryId = Number(this.$route.query.category);
      if (categoryId) this.form.categoryId = categoryId;
    },
    SET_IF_EDIT() {
      this.form = {
        ...this.product,
        categoryId: this.product.category.id,
        imagePath: this.$api.image(this.product.imagePath),
        videoPath: this.$api.image(this.product.videoPath),
        video: null,
        image: null,
      };
      const processingList = this.getRezervationRoom.Processes.filter((p) =>
        this.form.processes
          .split(",")
          .map((fp) => Number(fp))
          .includes(p.Id)
      );
      if (processingList.length > 0) this.processList = processingList;
    },
    SET_FILE(e) {
      this.form.image = e.file; // image file'ı
      this.files.image = e.uri; // image linki
    },
    SET_VIDEO(e) {
      this.form.video = e.file; // video file'ı
      this.files.video = e.uri; /// video linki
    },
    SET_LINK(link) {
      this.form.video = null;
      this.files.video = link;
    },
    // middleware
    SET_MIDDLEWARE(e) {
      e.preventDefault();
      if (this.edit) this.SEND_UPDATE_SEANCE();
      else this.SEND_SEANCE();
    },
    // ürünü güncelle
    async SEND_UPDATE_SEANCE() {
      if (this.CONTROL_OF_PROCCESS(this.$t("pleaseSelectService"))) return;
      const response = await this.$store.dispatch("SEND_UPDATE_SEANCE", {
        fd: this.SET_SEANCE_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath:
            this.files.image === null
              ? this.product.imagePath
              : this.files.image,
          videoPath:
            this.files.video === null
              ? this.product.videoPath
              : this.files.video,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
    },
    // ürünü gönder
    async SEND_SEANCE() {
      if (this.CONTROL_OF_PROCCESS(this.$t("pleaseSelectService"))) return;
      if (this.form.image === null)
        return this.SET_ALERT_BOX("danger", this.$t("imageIsRequired"));
      // if (this.form.video === null) return;
      const response = await this.$store.dispatch("SEND_NEW_SEANCE", {
        fd: this.SET_SEANCE_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath: this.files.image,
          videoPath: this.files.video,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.push(`/product/seances-${response}`);
        }, 2500);
    },
    // control of process process choosing is required;
    CONTROL_OF_PROCCESS(message) {
      if (this.processList.length === 0) {
        this.$store.commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message,
          },
        });
        return true;
      } else return false;
    },
    // ürünü formdataya taşı.
    SET_SEANCE_BY_FORMDATA(form) {
      const fd = new FormData();
      fd.append("UserId", form.userInfo.id);
      fd.append("Title", form.title);
      fd.append("CategoryId", form.categoryId);
      fd.append("Description", form.description);
      fd.append("HiddenInfo", form.hiddenInfo);
      fd.append("LangCode", form.langCode);
      fd.append("Level", form.level);
      fd.append("Overview", form.overview);
      fd.append("ImagePath", form.imagePath);
      fd.append("IsSharable", form.isSharable);
      fd.append("IsShowcase", form.isShowcase);
      fd.append("IsOffer", form.isOffer);
      fd.append("Processes", this.processList.map((p) => p.Id).join(","));
      if (this.form.video !== null) {
        fd.append("Video", form.video);
        fd.append("VideoPath", "");
      } else if (this.form.video === null && this.files.video !== "")
        fd.append(
          "VideoPath",
          "https://www.youtube.com/watch?v=" + this.files.video
        );
      else fd.append("VideoPath", form.videoPath);
      if (this.form.image !== null) fd.append("Image", form.image);
      return fd;
    },
  },
  created() {
    if (this.edit) this.SET_IF_EDIT();
    this.SET_CATEGORY();
  },
};
</script>
