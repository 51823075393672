<template>
  <div class="my-4">
    <h4>
      <img src="/images/rez-logo.png" width="40" alt="" />
      {{ $t("serviceProductProvide") }}
    </h4>
    <div class="alert mt-4 alert-info">
      {{ $t("cameFromRezz") }}
    </div>
    <CheckRezervationConnected>
      <Tables
        no-search
        checkable
        checked-key="Id"
        @change:checkedList="(e) => (checkedList = e)"
        :headers="headers"
        :check-value="value"
        :items="getRezervationRoom.Processes"
      >
        <template #Duration="{ item }"> {{ item.Duration }}dk. </template>
        <template #Price="{ item }">
          {{ Currency({ price: item.PriceTRY }, "TRY") }} /
          {{ Currency({ price: item.PriceEUR }, "USD") }} /
          {{ Currency({ price: item.PriceUSD }, "USD") }} /
        </template>
      </Tables>
    </CheckRezervationConnected>
  </div>
</template>

<script>
import CheckRezervationConnected from "../Shared/CheckRezervationConnected.vue";
import Tables from "../Tables/index.vue";
import { Currency } from "gurhan/helpers/Currency";
export default {
  data() {
    return {
      checkedList: [],
      headers: [
        { title: "Hizmet Adı", value: "Name", col: 12, md: 6, lg: 3 },
        { title: "Fiyatı", value: "Price", col: 12, md: 6, lg: 6 },
        { title: "Süresi(dk)", value: "Duration", col: 12, md: 6, lg: 3 },
        // { title: "Puanı", value: "Point", col: 12, md: 6, lg: 3 },
      ],
    };
  },
  props: {
    value: {
      default: () => [],
    },
  },
  watch: {
    checkedList(data) {
      this.$emit("input", data);
    },
  },
  computed: {
    // rezervasyondan gelen hizmetler
    getRezervationRoom() {
      return this.$store.state.Rezervation.activeRoom;
    },
    // rezervasyondan gelen hizmetler
    getConnected() {
      return this.$store.state.Rezervation.connected;
    },
  },
  components: { CheckRezervationConnected, Tables },
  methods: {
    Currency,
    SET_CHECKED_LIST(item) {
      const index = this.checkedList.findIndex((c) => c.Id === item.Id);
      if (index !== -1) this.checkedList.splice(index, 1);
      else this.checkedList.push(item);
    },
    // control of process process choosing is required;
    CONTROL_OF_PROCCESS(message) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: true,
          variant: "danger",
          message,
        },
      });
    },
  },
  mounted() {
    if (!this.getConnected)
      this.CONTROL_OF_PROCCESS(this.$t("reservationSystemNotConnected"));
  },
};
</script>

<style></style>
